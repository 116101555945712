// App.js
import React from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faTelegramPlane, faMastodon, faSteam, faUnsplash, faApple, faSpotify } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPen, faMusic } from '@fortawesome/free-solid-svg-icons';
import MusicNote from './MusicNote';
import { ReactComponent as GrafanaLogo } from './icons/grafana.svg'; // 使用 ReactComponent 作为 SVG 图标

// 音符图标组件
function NoteIcon() {
    return <FontAwesomeIcon icon={faMusic} className="note-icon floating" />;
}

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <MusicNote className="music-note" />
                <h1 className="App-title">Bdim - 减三和弦 <NoteIcon /></h1>
                <div className="App-links">
                    <a href="https://github.com/bdim404" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faGithub}/>
                    </a>
                    <a href="https://t.me/bdim404" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faTelegramPlane}/>
                    </a>
                    <a href="mailto:work@bdim.moe" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faEnvelope}/>
                    </a>
                    <a href="https://blog.bdim.moe" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faPen}/>
                    </a>
                    <a href="https://mastodon.social/@Bdim404" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faMastodon}/>
                    </a>
                    <a href="https://steamcommunity.com/id/bdim/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faSteam}/>
                    </a>
                    <a href="https://unsplash.com/@bdim404" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faUnsplash}/>
                    </a>
                    <a href="https://grafana.bdim.moe" target="_blank" rel="noopener noreferrer">
                        <GrafanaLogo className="icon" />
                    </a>
                    <a href="https://music.apple.com/us/playlist/favorite-songs/pl.u-V5U3loe9by" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faApple}/>
                    </a>
                    <a href="https://open.spotify.com/user/31pcxjqgpujwzcrqi7ibm6vb6bpu?si=32b385e2ba9d4477" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faSpotify}/>
                    </a>
                </div>
                <p className="custom-style">中文 & English</p>
                <p className="custom-style">二胡 音乐表演本科在读</p>
                <p className="custom-style">人工智能音乐 Music AI learner</p>
                <p className="custom-style">摄影 Photography Enthusiast</p>
                <p className="custom-style">Undergraduate in Music Performance</p>
                <p className="custom-style">Erhu-Performer & Computer-enthusiast</p>
            </header>
        </div>
    );
}

export default App;
